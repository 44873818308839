export default {
  messages: {
    uk: {
      'Авторизуватись через': 'Авторизуватись через',
      'Авторизований через': 'Авторизований через'
    },
    ru: {
      'Авторизуватись через': 'Авторизироваться через',
      'Авторизований через': 'Авторизованный через'
    }
  }
}
