export default {
    messages: {
        uk: {
            'Авторизуватись через': 'Авторизуватись через',
            'Авторизований через': 'Авторизований через',
            'Підтвердити власність авто': 'Підтвердити власність авто',
        },
        ru: {
            'Авторизуватись через': 'Авторизироваться через',
            'Авторизований через': 'Авторизованный через',
            'Підтвердити власність авто': 'Подтвердить собственность авто',
        }
    }
}
